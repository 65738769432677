/* Main content should take up all available space */
#root {
  flex: 1;
}

.footer {
  background-color: #000000; /* Dark background */
  color: white; /* White text color */
  padding: 40px 20px; /* Padding for footer */
  text-align: center; /* Center text */
}

.footer-container {
  display: flex; /* Flex layout for sections */
  justify-content: center; /* Space out sections */
  align-items: center;
  max-width: 1200px; /* Max width */
  margin: 0 auto; /* Center horizontally */
  flex-wrap: wrap; /* Wrap items on smaller screens */
}

.footer-section {
  flex: 1; /* Allow sections to grow equally */
  margin: 10px; /* Margin between sections */
  text-align: left; /*
  

}

.footer-section h3 {
  margin-bottom: 10px; /* Space below heading */
}

.footer-section2 {
  flex: 1; /* Allow sections to grow equally */
  margin: 10px; /* Margin between sections */
}

.footer-section2 h3 {
  margin-bottom: 10px; /* Space below heading */
}

.social-icons a {
  margin: 0 10px; /* Space between icons */
  color: white; /* White text for links */
  text-decoration: none; /* Remove underline */
}



.footer-bottom {
  margin-top: 20px; /* Space above the bottom section */
  display: flex; /* Use flexbox for layout */
  justify-content: center; /* Space out the items */
  align-items: center; /* Align items vertically */
}

.footer-bottom p {
  margin: 10px 0; /* Space between paragraphs */
}


.footer-links {
  display: flex; /* Use flexbox for links */
  gap: 10px; /* Space between links */
}

.footer-links a {
  color: white; /* White text for links */
  text-decoration: none; /* Remove underline */
}
