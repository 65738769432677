

.signature-container {
  border: 1px solid #000;
  width: 100%;
  height: 200px;
  margin: 10px 0;
  position: relative;
  animation: fadeIn 2s ease-in-out;
}

.signature-pad {
  width: 100%;
  height: 100%;
}


*{
box-sizing: border-box;
}
.body {
width: 100%;
background-color: black !important;
animation: slideInFromLeft 1.5s ease-out;
}

.justified-text {
text-align: justify;
}

.hero {
margin-top: 5%;
background-color: #A9ABAE;
height: 60vh;
display: flex;
justify-content: center;
align-items: center;
color: rgb(255, 255, 255);
text-align: center;
position: relative;
border-bottom-left-radius: 70% 30%;
border-bottom-right-radius: 70% 30%;
max-width: 100%;
background-image: url('../assets/bg2.png');
background-size: fit;
background-position: center;
animation: grow 2s ease-in-out;

}

.hero-content {
max-width: 600px;
align-items: center;
animation: fadeIn 3s ease-in;
}

.hero h1 {
font-size: 68px;
margin-bottom: 5%;
animation: slideInFromTop 1.5s ease-out;
}

.hero p {
font-size: 30px;
margin-bottom: 5%;
animation: slideInFromTop 1.5s ease-out;
}

.cta-button {
background-color: #383780;
color: white;
padding: 12px 20px;
border-radius: 30px;
border: none;
font-size: 1rem;
font-weight: 500;
cursor: pointer;transition: background-color 0.3s ease;
margin-bottom: 20%;
animation: bounce 2s infinite;
}

.cta-button:hover {
background-color: #ffffff;
color: #383780;
}

.cta-button1 {
  background-color: #383780;
  color: white;
  padding: 6px 10px;
  border-radius: 30px;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;transition: background-color 0.3s ease;
  margin-bottom: 20%;
  animation: pulse 1.5s infinite;
  }
  .cta-button1:hover {
  background-color: #ffffff;
  color: #383780;
  }

section {
padding: 4rem 2rem;
animation: fadeIn 2s ease-in;


}
.hero-content {
max-width: 800px;
}
.info-section {
display: flex;
justify-content: center;
align-items: center;
padding: 40px;
background-color: #ffffff;
border-radius: 20px;
margin: 20px 0;
min-height: 400px;
animation: slideUp 1.5s ease-out;
}

.info-container {
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
max-width: 1200px;
}

.top-column {
  width: 100%;
  text-align: center;
  margin-bottom: 20px; /* Space between top column and cards */
  animation: fadeIn 2s ease-in;
}

.top-column h1 {
  color: #4a586e;
  animation: slideInFromLeft 1s ease-in-out;
}

.top-column h2 {
  color: #4a586e;
  animation: slideInFromRight 1s ease-in-out;
}

.column1, .column2 {
flex: 1;
padding: 20px;
max-width: 45%;
animation: fadeIn 1.5s ease-in-out;
}

.column1 .video-wrapper iframe {
width: 100%;
height: 315px;
border-radius: 5px;
border: none;
box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
animation: fadeIn 1.5s ease-in-out;
}

.column2 h1 {
  color: #4a586e;
  font-size: 1.5rem;
  line-height: 1.6;
  margin-bottom: 20px;
  font-weight: bolder;
  }

.column2 h3 {
color: #4a586e;
font-size: 1.5rem;
line-height: 1.6;
margin-bottom: 20px;
}
.cards-section {
display: flex;
justify-content: center;
padding: 20px 100px;
background-color: #ffffff;
}

.cards-section1{
background-color: #383780;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}

.cards-section3{
  background-color: #383780;

  }

.cards-container {
display: flex;
justify-content: space-between;
align-items: flex-start;
width: 100%;
max-width: 1200px;
padding: 10px;
}

.cards-container1 {
display: flex;
justify-content: center;
align-items: flex-start;
width: 100%;
max-width: 10px;
padding: 1px;
margin: auto;
}

.cards-row {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin: 0;
  padding: 0;
}

.card {
background-color: white;
border-radius: 15px;
box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
text-align: center;padding: 20px;
width: 30%;
margin: 50px 5%;
height: 30vh;
color: #4a586e;
}
.card1 {
  background-color: #ffffff;
  border-radius: 50px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
  padding: 10px;
  width: 100%;
  max-width: 1200px;
  margin: 10px 10px;
  height: 20vh;
  display: flex;
  align-items: center;
  padding: auto;
  }



.card img {
width: 60px;
margin-bottom: 20px;
}
.card1 img {
  width: 50px;
  margin-right: 20px;
  }
.card1 h3 {
  font-size: 1.25rem;
  color: #4a586e;
  margin-bottom: 10px;
}
.card1 p {
  font-size: 0.95rem;
  color: #6b7c93;
  line-height: 1;

}
.card2 img {
width: 100%;
border-radius: 10px;
}
.card h3 {
font-size: 1.25rem;
color: #4a586e;
margin-bottom: 10px;
}
.card p {
font-size: 0.95rem;
color: #6b7c93;
line-height: 1.5;
}

.card:hover {
transform: translateY(-15px);
transition: transform 0.5s ease;
}

.card1:hover {
  transform: translateY(-15px);
  transition: transform 0.5s ease;
  }

  .cards-section2 {
    background-color: white;
    color: rgb(0, 27, 53);
    padding: 50px 0;
    text-align: center;
  }

  .column-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .top-column {
    margin-bottom: 30px;
  }

  .top-column2 {
    margin-bottom: 30px;
  }

  .top-column h1 {
    font-size: 2.5rem;
    font-weight: bold;
  }

  .top-column2 h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #4a586e;
    
  }

  .top-column2 h2 {
    background-color: #ffffff;
    font-weight: bold;
 
    color: #4a586e;

  }

  .column h3 {
    color: #4a586e;
  }

  .column ul {
    color: #4a586e;
  }

  .column p {
    color: #4a586e;
  }
  
  .content-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
  }

  .content-container h2 {
      background-color: #ffffff;
      font-weight: bold;

      color: #4a586e;

  }
  
  .column {
    flex: 1;
    padding: 20px;
    text-align: left;
  }

  .column h1{
    font-size: 2.5rem;
    font-weight: bold;
  }
  
  .column h3 {
    font-size: 1.75rem;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .column ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .column li {
    font-size: 1rem;
    margin-bottom: 10px;
    line-height: 1.5;
  }

  .form-section {
    width: 100%;
    max-width: 365px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #7c0606;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
    margin: auto;
    margin-bottom: 50px;
    min-height: 200px;
    overflow-x: hidden; /* Prevents horizontal scrolling */
  }

.form-container {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  background-color: #f7f9fc;
  border-radius: 10px;
  text-align: center;
  color: #4a586e;
}

.form-container h1 {
  color: #4a586e;
  font-size: 50px;
}


h2 {
font-size: 24px;
margin-bottom: 10px;
}
p{
font-size: 16px;
color: #4a586e;
margin-bottom: 20px;
}
form {
display: flex;
flex-direction: column;
}
.form-input {
margin-bottom: 15px;
padding: 10px;
font-size: 16px;
border-radius: 20px;
border: 1px solid #ccc;

}.submit-btn {
  background-color: #383780;
  color: white;
  padding: 12px 20px;
  border-radius: 30px;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;transition: background-color 0.3s ease;
  margin-bottom: 20%;
}

/* Ensure inputs and buttons don't overflow */
.form-input, .submit-btn {
  width: 100%; /* Make inputs and buttons take full width */
  max-width: 100%; /* Prevent them from exceeding container width */
  box-sizing: border-box; /* Make padding part of the width */
}
.submit-btn:hover {
background-color: #ffffff;
color: black;
}

.submit-btn1:hover {
  background-color: #ffffff;
  color: black;
  }

.footer-text {
font-size: 20px;
color: #4a586e;
margin-top: 5px;
margin-bottom: 20%;
}
.footer-text a {
color: #007bff;
text-decoration: none;
}

@media (max-width: 992px) {
  .column1 .video-wrapper iframe {
    width: 100%;
    height: 315px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }
}

@media (max-width: 480px) {
  .form-section {
    padding: 10px;
  }

  .form-container {
    padding: 5px;
  }

  .form-input {
    padding: 8px;
  }

  .submit-btn {
    font-size: 0.9rem;
    padding: 10px 16px;
  }
}
/* Media Queries *//* For tablets and smaller devices (768px and below) */
@media (max-width: 768px) {
.hero h1 {
font-size: 48px;
}
.hero p {
font-size: 24px;
}
.info-container, .cards-container, .cards-container1, .cards-row {
flex-direction: column;
}
.column1, .column2, .card, .card1 {
max-width: 100%;
}
.card {
margin: 20px 0;
width: 100%;
}

.card1 {
  margin: 20px 0;
  width: 100%;
  }

.cta-button {
margin-bottom: 10%;}

.card, .form-container, .card1 {
  width: 100%;
  margin: 20px 0;
  }

.form-section{
  width: 100%;
  margin: 10px 10px 50px 10px;
}
  

}
/* For mobile devices (480px and below) */
@media (max-width: 480px) {
.hero h1 {
font-size: 36px;
}
.hero p {
font-size: 18px;
}
.cards-section, .cards-section1 {
padding: 10px 20px;
}
.card, .form-container, .card1 {
width: 100%;
margin: 20px 0;
}

.cta-button {
font-size: 0.8rem;
padding: 8px 16px;
}}
/* For larger screens (1200px and above) */
@media (min-width: 1200px) {
.hero h1 {
font-size: 72px;
}
.hero p {
font-size: 36px;
}
.cta-button {
font-size: 1.2rem;
padding: 16px 32px;
}
.card {
width: 28%;
}

.card1 {
  width: 28%;
  }

}


/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromTop {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes grow {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}