/* Default Styles */
.navbar {
  background-color: white;
  padding: 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed; /* Fixes the navbar at the top */
  top: 0; 
  left: 0;
  right: 0;
  z-index: 1000;
  height: 100px;
}

.logo {
  width: 10%;
  margin-left: 5%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.logo img {
  width: 100%;
  
}

.nav-links {
  color: #A9ABAE;
  display: flex; /* Initially hidden */
  gap: 20px;
  list-style: none;
  margin-left: 5%;
  transform: translateY(0); /* Move into view */
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transitions */
}

/* Background color for the entire navbar when menu is open */
.nav-links.open {
  display: flex; /* Show when open */
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
  z-index: 999; /* Bring the menu above other elements */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Adds shadow */
  padding: 20px 0; /* Adds vertical padding when open */
  border-radius: 8px; /* Adds rounded corners */
  margin-top: 0%;
  color: #A9ABAE;
}

.nav-links li a {
  color: #383780;
  text-decoration: none;
  padding: 10px 15px;
}

.nav-links li a:hover {
  color: white;
  background-color: #383780;
  border-radius: 4px;
}

.arrow-down {
  transition: transform 0.3s ease;
}

.dropdown.open .arrow-down {
  transform: rotate(180deg); /* Rotate the arrow when dropdown is open */
}

.nav-links.open li a:focus {
  outline: 2px solid #007BFF; /* Focus outline for accessibility */
}

/* Dropdown Styles */
.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  background-color: #222;
  display: none;
  flex-direction: column;
  padding: 10px 0;
  list-style: none;
  top: 100%;
  left: 0;
  min-width: 150px;
  border-radius: 4px;
  max-height: 400px; /* Maximum height */
  overflow-y: auto; /* Scroll if content exceeds the height */
}

.dropdown-menu li a {
  padding: 15px 20px; /* Increased padding */
  color: #fff;
  text-decoration: none;
}

.dropdown-menu li a:hover {
  background-color: none !important;
  text-decoration: underline;
}

/* Show dropdown when open */
.dropdown:hover .dropdown-menu {
  display: flex;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  cursor: pointer;
  margin-right: 5%;
}

.hamburger .bar {
  height: 3px;
  width: 100%;
  background-color: #403F85;
  border-radius: 2px;
  transition: all 0.3s ease;
}

/* Media Queries */

/* Show Hamburger and toggle menu on small screens */
@media (max-width: 992px) {
  .nav-links {
    position: absolute;
    top: 50px; /* Adjust this to the height of your navbar */
    right: 5%; /* Align it with the right side near hamburger */
    width: auto; /* Set to auto to avoid full width */
    background-color: rgb(0, 0, 0);
    flex-direction: column;
    display: none; /* Initially hidden */
    z-index: 999; /* Ensure it appears above other elements */
  }

  .nav-links li a {
    color: #ffffff;
    text-decoration: none;
    padding: 10px 15px;
  }
  
  .nav-links li a:hover {
    background-color: none;
    border-radius: 4px;
    text-decoration: underline;
  }

  .nav-links.open {
    display: flex; /* Show when open */
  }

  .dropdown-menu {
    position: static; /* Show dropdown below the item */
  }

  .hamburger {
    display: flex; /* Show hamburger on small screens */
    position: absolute;
    top: 10px;
    right: 5%;
    z-index: 1000; /* Ensure it stays above the nav links */
    margin-top: 30px;
  }
  .logo {
    width: 100px;
    margin-left: 5%;
  }
}

/* Small Devices (Max-width 480px) */
@media (max-width: 480px) {
  .logo {
    width: 150px; /* Smaller logo size for mobile screens */
  }

  .nav-links {
    padding: 10px;
    top: 40px; /* Adjust height based on your design */
    right: 5%; /* Align near hamburger */
  }

  .nav-links li a {
    padding: 10px; /* Adjust padding for better touch targets */
  }
}


/* Hamburger Animation */
.hamburger.open .bar:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open .bar:nth-child(2) {
  opacity: 0; /* Hide the middle bar */
}

.hamburger.open .bar:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}
